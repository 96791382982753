/* eslint-disable func-names */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
const isUserLoggedIn = () => localStorage.getItem('token');

const isTokenExpired = () => {
  const token = localStorage.getItem('token');
  const expiresIn = parseInt(localStorage.getItem('expiresIn'), 10); // Parse expiresIn as an integer
  if (!token || Number.isNaN(expiresIn)) {
    return false; // Token or expiration time not found or not a valid number
  }

  const now = Math.floor(Date.now() / 1000); // Get the current time in seconds
  return now >= expiresIn; // Check if the token is expired
};

const isUserVerified = () => {
  const isVerified = localStorage.getItem('isVerified');
  return isVerified === 'true'; // Converts the string 'true' to a boolean
};

const classNames = (...classes) => classes.filter(Boolean).join(' ');

const fixedDigitNumber = (num, digits = 4) => (num).toLocaleString('en-US', { minimumIntegerDigits: digits, useGrouping: false });

const formatUSPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

const timezonesList = [
  { name: 'Eastern Standard Time (EST)', value: '1' },
  { name: 'Central Standard Time (CST)', value: '2' },
  { name: 'Pacific Standard Time (PST)', value: '3' },
  { name: 'Mountain Standard Time (MST)', value: '4' },
  { name: 'Hawaii–Aleutian Standard Time (HST)', value: '5' },
  { name: 'Alaska Standard Time (AKST)', value: '6' },
];

const gendersList = [
  { name: 'Male', value: 'male' },
  { name: 'Female', value: 'female' },
  { name: 'Non-binary', value: 'non-binary' },
];

const mimeTypeMap = {
  pdf: 'application/pdf',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // Add more file extensions and corresponding MIME types here
};

const appointmentStatus = (status = '') => {
  switch (status) {
    case 'completed': return {
      text: 'Completed',
      class: 'bg-secondary-500 text-white',
    };
    case 'canceled': return {
      text: 'Canceled',
      class: 'bg-error-500 text-white',
    };
    case 'in-progress': return {
      text: 'On the way',
      class: 'bg-alert-500 text-white',
    };
    case 'rescheduled': return {
      text: 'Rescheduled',
      class: 'bg-alert-600 text-white',
    };

    default: return {
      text: 'Completed',
      class: '',
    };
  }
};

const serializeQueryParams = function (obj, prefix) {
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push((v !== null && typeof v === 'object')
        ? serializeQueryParams(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
  }
  return str.join('&');
};

const getFileNameFromUrl = (url) => {
  const parts = url.split('/');
  const encodedFileName = parts[parts.length - 1];
  const decodedFileName = decodeURIComponent(encodedFileName);
  return decodedFileName;
};

const getFileTypeFromUrl = (url) => {
  const extension = url.split('.').pop().toLowerCase();
  return mimeTypeMap[extension] || null;
};

const readCssFile = async (filePath) => {
  const response = await fetch(filePath);
  const cssContent = await response.text();
  return cssContent;
};

export {
  readCssFile,
  isUserLoggedIn,
  isUserVerified,
  isTokenExpired,
  classNames,
  fixedDigitNumber,
  formatUSPhoneNumber,
  timezonesList,
  gendersList,
  serializeQueryParams,
  appointmentStatus,
  getFileNameFromUrl,
  getFileTypeFromUrl,
};
