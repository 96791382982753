/* eslint-disable import/prefer-default-export */
const apiUrl = 'https://api.personalcareagencies.org/api';
// const apiUrl = 'http://18.246.86.18:8000/api';
// const apiUrl = 'http://localhost:8000/api';
const caregivers = [
  {
    name: 'Micheal',
    img: 'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHVzZXJzfGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60',
    patient: 'asdasd',
    onDuty: false,
  },
  {
    name: 'Micheal',
    img: 'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHVzZXJzfGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60',
    patient: 'asdasdasd',
    onDuty: false,
  },
  {
    name: 'Micheal',
    img: 'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHVzZXJzfGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60',
    patient: 'asdasd',
    onDuty: false,
  },
  {
    name: 'Micheal',
    img: 'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHVzZXJzfGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60',
    patient: 'asdaasdsd',
    onDuty: true,
  },
  {
    name: 'Micheal',
    img: 'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHVzZXJzfGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60',
    patient: 'asdasdaasd',
    onDuty: false,
  },
];
const appointments = [
  {
    name: 'Micheal',
    img: 'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHVzZXJzfGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60',
    patient: 'asdasd',
    paid: true,
    status: 'in-progress',
    time: '12:00 PM',
  },
  {
    name: 'Micheal',
    img: 'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHVzZXJzfGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60',
    patient: 'asdasd',
    paid: true,
    status: 'completed',
    time: '12:00 PM',
  },
  {
    name: 'Micheal',
    img: 'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHVzZXJzfGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60',
    patient: 'asdasd',
    paid: true,
    status: 'canceled',
    time: '12:00 PM',
  },
  {
    name: 'Micheal',
    img: 'https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHVzZXJzfGVufDB8fDB8fHww&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60',
    patient: 'asdasd',
    paid: true,
    status: 'rescheduled',
    time: '12:00 PM',
  },
];

const race = [
  {
    name: 'American Indian',
    id: 1,
  },
  {
    name: 'Asian',
    id: 2,
  },
  {
    name: 'Black or African American',
    id: 3,
  },
  {
    name: 'Hispanic or Latino',
    id: 4,
  },
  {
    name: 'Native Hawaiian or Other Pacific Islander',
    id: 5,
  },
  {
    name: 'White',
    id: 6,
  },
];

export {
  caregivers, appointments, apiUrl, race,
};
