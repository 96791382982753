/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../constants';
import handleUnauthorized from '../middleware/apiMiddleware';
import { serializeQueryParams } from '../../utils';

export const silverLiningAdminApi = createApi({
  reducerPath: 'silverLiningAdminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(handleUnauthorized),
  tagTypes: ['taskService', 'services', 'clientDetail', 'staffAttendance', 'dmsDocs'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password, rememberMe = false }) => ({
        url: '/login',
        method: 'POST',
        body: {
          email,
          password,
          rememberMe,
        },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .catch((err) => {
          });
      },
    }),
    verify: builder.mutation({
      query: ({ otpToken }) => ({
        url: '/verify',
        method: 'POST',
        body: { otpToken },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .catch((error) => {
          });
      },
    }),
    forgotPassword: builder.mutation({
      query: ({ email }) => ({
        url: '/recover-password',
        method: 'POST',
        body: { email },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch(() => {
          });
      },
    }),
    resetPassword: builder.mutation({
      query: ({ newPassword }) => ({
        url: '/reset-password',
        method: 'POST',
        body: { password: newPassword },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch(() => {
          });
      },
    }),
    getRoles: builder.query({
      query: () => ({
        url: '/get-roles',
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    register: builder.mutation({
      // Pass formData as a parameter
      query: (formData) => ({
        url: '/register',
        method: 'POST',
        body: formData,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getUsers: builder.query({
      query: (params) => {
        let url = '/get-users';
        if (params) {
          const queryString = new URLSearchParams(params).toString();
          if (queryString) {
            url += `?${queryString}`;
          }
        }
        return {
          url,
          method: 'GET',
        };
      },
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    registerAgency: builder.mutation({
      // Pass formData as a parameter
      query: (formData) => ({
        url: '/save-agency',
        method: 'POST',
        body: formData,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getAgencies: builder.query({
      query: (params) => {
        let url = '/get-agency';
        if (params) {
          const queryString = new URLSearchParams(params).toString();
          if (queryString) {
            url += `?${queryString}`;
          }
        }
        return {
          url,
          method: 'GET',
        };
      },
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {

          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    resendOtp: builder.mutation({
      query: () => ({
        url: '/resend',
        method: 'GET',
        // body: {  },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {

          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getUserById: builder.query({
      query: (params) => {
        let url = '/get-user-by-id';
        if (params) {
          const queryString = new URLSearchParams(params).toString();
          if (queryString) {
            url += `?${queryString}`;
          }
        }
        return {
          url,
          method: 'GET',
        };
      },
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    deleteUserById: builder.mutation({
      query: (userId) => ({
        url: `/delete-user/${userId}`, // Adjust the URL according to your API endpoint.
        method: 'DELETE',
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    blockUser: builder.mutation({
      query: ({ userId, reason }) => ({
        url: '/block-user',
        method: 'POST',
        body: {
          userId,
          reason,
        },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    terminateUser: builder.mutation({
      // Pass userId and reasonForBlock as parameters
      query: ({ userId, reason, isPermanentBlock }) => ({
        url: '/terminate-user',
        method: 'POST',
        body: {
          userId,
          reason,
          isPermanentBlock,
        },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    updateStaff: builder.mutation({
      // Pass formData as a parameter
      query: (formData) => ({
        url: '/update-staff',
        method: 'POST',
        body: formData,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    uploadUserImg: builder.mutation({
      // Pass formData as a parameter
      query: (formData) => ({
        url: '/uploadImage',
        method: 'POST',
        body: formData,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    deleteAgencyById: builder.mutation({
      query: (agencyId) => ({
        url: `/delete-agency/${agencyId}`, // Adjust the URL according to your API endpoint.
        method: 'DELETE',
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    blockAgency: builder.mutation({
      query: ({ agencyId, reason }) => ({
        url: '/block-agency',
        method: 'POST',
        body: {
          agencyId,
          reason,
        },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    unBlockAgency: builder.mutation({
      query: ({ agencyId, reason }) => ({
        url: '/unBlock-agency',
        method: 'POST',
        body: {
          agencyId,
          reason: '',
        },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getDocType: builder.query({
      query: () => ({
        url: '/document-type',
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            // Handle the response
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    service: builder.query({
      query: () => ({
        url: '/service',
        method: 'GET',
      }),
      providesTags: ['services'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => { handleUnauthorized(error); });
      },
    }),
    updateService: builder.mutation({
      query: ({ params, params2, params3 }) => ({
        url: `/service/${params}`,
        method: 'PUT',
        body: {
          hours: params2,
          units: params3,
        },
      }),
      invalidatesTags: ['services'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => { handleUnauthorized(error); });
      },
    }),
    getTask: builder.query({
      query: (Id) => ({
        url: `/get-task/key=taskId&id=${Id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch(({ error }) => {
            handleUnauthorized({ error });
          });
      },
    }),

    taskService: builder.mutation({
      query: ({ Id, data }) => ({
        url: `/task-service/${Id}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { data },
      }),
      invalidatesTags: ['taskService'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized({ error });
          });
      },
    }),

    uploadMasterDocument: builder.mutation({
      // Pass formData as a parameter
      query: (formData) => ({
        url: '/upload-master-document',
        method: 'POST',
        body: formData,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getMasterDocuments: builder.query({
      query: (params) => {
        let url = '/get-master-document-by-agencyId';
        if (params) {
          const queryString = new URLSearchParams(params).toString();
          if (queryString) {
            url += `?${queryString}`;
          }
        }
        return {
          url,
          method: 'GET',
        };
      },
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {

          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getRenewals: builder.query({
      query: (params) => {
        let url = '/renewal';
        if (params) {
          const queryString = new URLSearchParams(params).toString();
          if (queryString) {
            url += `?${queryString}`;
          }
        }
        return {
          url,
          method: 'GET',
        };
      },
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {

          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getTaskServiceData: builder.query({
      query: ({ Id }) => ({
        url: `/task-service/${Id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['taskService'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized({ error });
          });
      },
    }),
    unBlockUser: builder.query({
      query: ({ Id }) => ({
        url: `/unBlock-user/${Id}`,
        method: 'GET',
        body: {
        },
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    deleteMasterDocById: builder.mutation({
      query: (docId) => ({
        url: `/master-document/${docId}`, // Adjust the URL according to your API endpoint.
        method: 'DELETE',
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    updateMasterDoc: builder.mutation({
      query: ({ formData, docId }) => {
        console.log('docId:', docId);
        return {
          url: `/update-master-document/${docId}`,
          method: 'PUT',
          body: formData,
        };
      },
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    uploadUserDoc: builder.mutation({
      query: (formData) => ({
          url: '/upload-user-document/',
          method: 'POST',
          body: formData,
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    deleteUserDocById: builder.mutation({
      query: (docId) => ({
        url: `/user-document/${docId}`, // Adjust the URL according to your API endpoint.
        method: 'DELETE',
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    updateUserDocStatus: builder.mutation({
      query: ({ body, docId }) => {
        console.log('body', body);
        return {
          url: `/admin-user-document-status-update/${docId}`,
          method: 'PUT',
          body,
        };
      },
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    updateUserDoc: builder.mutation({
      query: ({ formData, docId }) => ({
          url: `/update-user-document/${docId}`,
          method: 'PUT',
          body: formData,
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getUserDocByUserId: builder.mutation({
      query: (body) => ({
          url: '/get-user-document-details/',
          method: 'POST',
          body,
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getUserDocByAgencyId: builder.mutation({
      query: (body) => ({
          url: '/get-user-document-by-agencyId/',
          method: 'POST',
          body,
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    registerDoctor: builder.mutation({
      // Pass formData as a parameter
      query: (body) => ({
        url: '/doctor',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getDoctors: builder.query({
      query: (params) => {
        let url = '/doctor';
        if (params) {
          const queryString = new URLSearchParams(params).toString();
          if (queryString) {
            url += `?${queryString}`;
          }
        }
        return {
          url,
          method: 'GET',
        };
      },
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {

          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    deleteDoctorById: builder.mutation({
      query: (doctorId) => ({
        url: `/doctor/${doctorId}`, // Adjust the URL according to your API endpoint.
        method: 'DELETE',
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    updateDoctor: builder.mutation({
      query: ({ body, doctorId }) => ({
        url: `/doctor/${doctorId}`,
        method: 'PUT',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getDoctorById: builder.query({
      query: (id) => {
        if (!id) {
          throw new Error("Missing required 'id' parameter");
        }
        const url = `/doctor/${id}`;
        return {
          url,
          method: 'GET',
        };
      },
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
            // Handle the successful response here
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getClientRoles: builder.query({
      query: () => ({
        url: '/get-client-roles',
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getClient: builder.query({
      query: (params) => ({
        // url: `/client-information?${params.length > 0 'agencyId=': ''}`,
        // url: params?.length > 0 && params !== undefined ? `/client-information?agencyId=${param
        // s}` : '/client-information',
        url: params?.length > 0 && params !== undefined ? `/client-information?agencyId=${params}` : '/client-information?&limit=100',
          method: 'GET',
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getClientbyFilter: builder.query({
      query: ({ clientUrl }) => ({
          url: clientUrl,
          method: 'GET',
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getClientById: builder.query({
      query: (id) => ({
          url: `/client-information/${id}`,
          method: 'GET',
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    registerClientInfo: builder.mutation({
      query: (body) => ({
        url: '/client-information',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    deleteClientById: builder.mutation({
      query: (clientId) => ({
        url: `/client-information/${clientId}`,
        method: 'DELETE',
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getDoctorBySearch: builder.query({
      query: (search) => ({
          url: `/doctor/key=nPINumber&id=${search}`,
          method: 'GET',
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    clientStatusBlocked: builder.mutation({
      query: ({ body, id }) => ({
        url: `/client-information/${id}`,
        method: 'PUT',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getAppointments: builder.query({
      // query: () => ({
      //   url: '/appointments',
      // query: (appointmentFilter) => ({
      //     url: `/appointments?pastUpcomingFilter=${appointmentFilter}`,
      //     method: 'GET',
      //   }),
      query: (Url) => ({
        url: Url,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getAppointmentById: builder.query({
      query: (id) => ({
          url: `/appointment/${id}`,
          method: 'GET',
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    bookAppointment: builder.mutation({
      query: (body) => ({
        url: '/appointment',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    appointmentReschedule: builder.mutation({
      query: ({ body, id }) => ({
        url: `/appointment/${id}/reschedule`,
        method: 'PUT',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    appointmentCancel: builder.mutation({
      query: ({ body, id }) => ({
        url: `/appointment/${id}/cancel`,
        method: 'PUT',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    appointmentComplete: builder.mutation({
      query: ({ body, id }) => ({
        url: `/appointment/${id}/complete`,
        method: 'PUT',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    appointmentNoShow: builder.mutation({
      query: ({ id }) => ({
        url: `/appointment/${id}/noShow`,
        method: 'PUT',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getClientCarePlan: builder.query({
      query: (clientId) => ({
          url: `/care-plan/${clientId}`,
          method: 'GET',
        }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    assessment: builder.mutation({
      query: (body) => ({
        // url: '/assessment',
        url: '/evaluation',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getPCTags: builder.query({
      query: (body) => ({
        url: '/pc-tag',
        method: 'GET',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getPCTagsById: builder.query({
      query: (id) => ({
        url: `/pc-tag/${id}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getTaskByPCTagsId: builder.query({
      query: (id) => ({
        url: `/get-task/key=pcTagId&id=${id}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    carePlan: builder.mutation({
      query: (body) => ({
        url: '/care-plan',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    carePlanAttendance: builder.mutation({
      query: (body) => ({
        url: '/carePlan/attendance',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getCarePlanAttendanceDetails: builder.query({
      query: ({ careplanId, startDate }) => ({
        url: `/carePlan/attendance/details?id=${careplanId}&date=${startDate}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getCarePlanAttendance: builder.query({
      query: ({ startDate, careplanId }) => ({
        url: `/carePlan/attendances?startDate=${startDate}&carePlanId=${careplanId}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getUsersAgencyRole: builder.query({
      query: ({ ...queryParams }) => ({
        url: `/get-users-by-agency-role?${serializeQueryParams({ ...queryParams })}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    task: builder.query({
      query: (body) => ({
        url: '/task/taskService',
        method: 'GET',
        body,
      }),
    }),
    createServiceTask: builder.mutation({
      query: (body) => ({
        url: 'task-service',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['taskService'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    updateMultiTaskService: builder.mutation({
      query: (body) => ({
        url: 'task-service/multiple',
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['taskService'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getEvaluations: builder.query({
      query: () => ({
        url: 'evaluations',
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getClientEvaluation: builder.query({
      query: (param) => ({
        url: `evaluations?clientId=${param}`,
        method: 'GET',

      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getStaffEvaluation: builder.query({
      query: ({ role, id }) => ({
        url: `evaluations?${role}=${id}`,
        method: 'GET',

      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    // this api for to update client notes via clientinfo id
    addClientNotes: builder.mutation({
      query: ({ id, payload }) => ({
        url: `client-information/${id}`,
        method: 'PUT',
        body: payload,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getCarePlan: builder.query({
      query: (params) => ({
        url: `care-plan/${params}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    assignCare: builder.mutation({
      query: (body) => ({
        url: 'care-assignment',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getCareAssignment: builder.query({
      query: (params) => ({
        url: `care-assignment/${params}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    dashboard: builder.query({
      query: ({ date, id }) => ({
        url: `dashboard/admin?forDate=${date}&agencyId=${id}`,
        // url: 'dashboard/admin?forDate=Feb 11 2024',
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    getClientReminder: builder.query({
      query: (id) => ({
        url: `reminders?clientId=${id}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    staffDashboard: builder.query({
      query: (date) => ({
        url: `dashboard/staff?forDate=${date}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    updateCarePlan: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/care-plan/${id}`,
        method: 'PUT',
        body: payload,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    approveBGCheck: builder.mutation({
      query: (payload) => ({
        url: '/user/consentForBackgroundCheck',
        method: 'PUT',
        body: payload,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    clientDetails: builder.mutation({
      query: (id) => ({
        url: `client/details/${id}`,
        method: 'POST',
      }),
      providesTags: ['clientDetail'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

    notification: builder.mutation({
      query: (body) => ({
        url: 'notification',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    reminder: builder.mutation({
      query: (body) => ({
        url: 'reminder',
        method: 'POST',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    careAssignment: builder.mutation({
      query: ({ id, payload }) => ({
        url: `care-assignment/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['clientDetail'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getClientCarePlanAttendance: builder.query({
      query: ({ ...queryParams }) => ({
        url: `carePlan/attendances?${serializeQueryParams({ ...queryParams })}`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getNotificationsId: builder.query({
      query: (params) => ({
        url: `notifications/${params}`,
        method: 'GET',
      }),
    }),
    getStaffAttendance: builder.query({
      query: () => ({
        url: 'staff/attendance',
        method: 'GET',
      }),
      providesTags: ['staffAttendance'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    updateStaffAttendance: builder.mutation({
      query: (body) => ({
        url: 'staff/attendance',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['staffAttendance'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getZoomToken: builder.query({
      query: (params) => ({
        url: `appointment/${params}/video/token`,
        method: 'GET',
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    getDMSDocs: builder.query({
      query: ({ ...params }) => ({
        url: `dmsDocuments?${serializeQueryParams({ ...params })}`,
        method: 'GET',
      }),
      providesTags: ['dmsDocs'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    generateDMSDoc: builder.mutation({
      query: (body) => ({
        url: 'dmsDocument',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['dmsDocs'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    editDMSDoc: builder.mutation({
      query: ({ id, payload }) => ({
        url: `dmsDocument/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['dmsDocs'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    signedDMSUpload: builder.mutation({
      query: ({ id, payload }) => ({
        url: `dmsDocument/complete/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['dmsDocs'],
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),
    updateCarePlanAttendanceTime: builder.mutation({
      query: ({ body, id }) => ({
        url: `/carePlan/attendances/${id}`,
        method: 'PUT',
        body,
      }),
      onQueryStarted(body, { queryFulfilled }) {
        queryFulfilled
          .then((response) => {
          })
          .catch((error) => {
            handleUnauthorized(error);
          });
      },
    }),

  }),
});

export const {
  useGetDMSDocsQuery,
  useLazyGetDMSDocsQuery,
  useEditDMSDocMutation,
  useSignedDMSUploadMutation,
  useGenerateDMSDocMutation,
  useUpdateServiceMutation,
  useGetClientbyFilterQuery,
  useApproveBGCheckMutation,
  useDashboardQuery,
  useGetClientReminderQuery,
  useGetZoomTokenQuery,
  useGetStaffAttendanceQuery,
  useUpdateStaffAttendanceMutation,
  useGetNotificationsIdQuery,
  useStaffDashboardQuery,
  useUpdateCarePlanMutation,
  useNotificationMutation,
  useReminderMutation,
  useCareAssignmentMutation,
  useClientDetailsMutation,
  useGetClientCarePlanAttendanceQuery,
  useLazyGetClientCarePlanAttendanceQuery,
  useTaskQuery,
  useLoginMutation,
  useVerifyMutation,
  useAssignCareMutation,
  useGetCarePlanQuery,
  useGetCareAssignmentQuery,
  useAddClientNotesMutation,
  useForgotPasswordMutation,
  useGetEvaluationsQuery,
  useGetClientEvaluationQuery,
  useGetStaffEvaluationQuery,
  useResetPasswordMutation,
  useUpdateMultiTaskServiceMutation,
  useGetRolesQuery,
  useRegisterMutation,
  useGetUsersQuery,
  useGetAgenciesQuery,
  useGetPCTagsQuery,
  useRegisterAgencyMutation,
  useResendOtpMutation,
  useGetUserByIdQuery,
  useDeleteUserByIdMutation,
  useBlockUserMutation,
  useTerminateUserMutation,
  useUpdateStaffMutation,
  useUploadUserImgMutation,
  useDeleteAgencyByIdMutation,
  useBlockAgencyMutation,
  useGetDocTypeQuery,
  useServiceQuery,
  useGetTaskQuery,
  useTaskServiceMutation,
  useUploadMasterDocumentMutation,
  useGetMasterDocumentsQuery,
  useGetRenewalsQuery,
  useCreateServiceTaskMutation,
  useGetTaskServiceDataQuery,
  useUnBlockUserQuery,
  useUnBlockAgencyMutation,
  useDeleteMasterDocByIdMutation,
  useUpdateMasterDocMutation,
  useUploadUserDocMutation,
  useDeleteUserDocByIdMutation,
  useUpdateUserDocStatusMutation,
  useUpdateUserDocMutation,
  useGetUserDocByUserIdMutation,
  useGetUserDocByAgencyIdMutation,
  useRegisterDoctorMutation,
  useGetDoctorsQuery,
  useDeleteDoctorByIdMutation,
  useUpdateDoctorMutation,
  useGetDoctorByIdQuery,
  useGetClientRolesQuery,
  useGetClientQuery,
  useGetClientByIdQuery,
  useRegisterClientInfoMutation,
  useDeleteClientByIdMutation,
  useGetDoctorBySearchQuery,
  useClientStatusBlockedMutation,
  useGetAppointmentsQuery,
  useGetAppointmentByIdQuery,
  useBookAppointmentMutation,
  useAppointmentRescheduleMutation,
  useAppointmentCancelMutation,
  useAppointmentCompleteMutation,
  useAppointmentNoShowMutation,
  useGetClientCarePlanQuery,
  useAssessmentMutation,
  useGetPCTagsByIdQuery,
  useGetTaskByPCTagsIdQuery,
  useCarePlanMutation,
  useCarePlanAttendanceMutation,
  useGetCarePlanAttendanceDetailsQuery,
  useGetCarePlanAttendanceQuery,
  useGetUsersAgencyRoleQuery,
  useLazyGetUsersAgencyRoleQuery,
  useUpdateCarePlanAttendanceTimeMutation,
} = silverLiningAdminApi;
