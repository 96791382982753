import { configureStore } from '@reduxjs/toolkit';
import { silverLiningAdminApi } from '../services/api';
import rootReducer from './rootReducer';
import authReducer from './authentication';
import calendarClientReducer from './calendarClientSlice';
// import handle401And403Middleware from '../services/middleware/apiMiddleware';

const store = configureStore({
  reducer: {
    [silverLiningAdminApi.reducerPath]: silverLiningAdminApi.reducer,
    ...rootReducer,
    authentication: authReducer,
    calendarClient: calendarClientReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(silverLiningAdminApi.middleware),
});

export default store;
