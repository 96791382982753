import React from 'react';

function formatName(name) {
  if (!name) return '';
  const nameParts = name.split(' ');
  if (nameParts.length < 2) return name; // Handle single-word names
  const [firstName, lastName] = nameParts;
  return `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)} ${lastName.charAt(0).toUpperCase()}${lastName.slice(1)}`;
}

function ProfileBadge({ onlyBadge = true }) {
  const email = localStorage.getItem('email');
  const name = localStorage.getItem('name');
  const profilePic = localStorage.getItem('profilePic');
  const formattedName = formatName(name);
  return (
    <button className="flex justify-center items-center gap-3 my-6 mb-10" title="View Profile">
      <div className="w-12 h-12">
        <img
          src={profilePic || 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60'}
          alt="profile"
          // className={`${onlyBadge ? 'h-12 w-12' : 'h-8 w-8'} rounded-full object-cover`}
          className={`${onlyBadge ? 'w-[100%] h-[100%]' : 'w-[100%] h-[100%]'} rounded-full object-cover`}
        />
      </div>
      { onlyBadge && (
      <div className="flex-1">
        <p className="text-white font-semibold text-left w-full">{formattedName || 'Aline DeShay'}</p>
        <p className="text-sm overflow-hidden overflow-ellipsis whitespace-nowrap max-w-xs w-44 text-left">{email || 'alina@yopmail.com'}</p>
      </div>
      )}
    </button>
  );
}

export default ProfileBadge;
