import { notification } from '../../components';

const handleUnauthorized = async (errorOrOptions) => {
  const error = errorOrOptions.error || errorOrOptions;
  if (error.status === 401 || error.status === 403) {
    if (error.status === 401) {
      notification({
        title: 'Log out',
        // description: error?.data?.message,
        description: error?.data?.message === 'Unauthorized' ? 'Session Expired' : error?.data?.message,
        type: 'error',
      });
    } else {
      notification({
        title: 'Error',
        // description: error?.data?.message,
        description: error?.data?.message,
        type: 'error',
      });
    }

    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  }
};

export default handleUnauthorized;
