import { createSlice } from '@reduxjs/toolkit';

const calendarClientSlice = createSlice({
  name: 'calendarClient',
  initialState: {},
  reducers: {
    setCalendarClient: (state, action) => action.payload,
  },
});

export const { setCalendarClient } = calendarClientSlice.actions;
export default calendarClientSlice.reducer;
